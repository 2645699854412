// 管理
import fetch from './fetch'

export default {
  fetchList (params) { // 列表
    return fetch(`/api/manage/regcode/list`, params, 'post', 'json', {})
  },
  updateRegCode(params) { // 创建或修改检验码
    return fetch(`/api/manage/regcode/updateRegCode`, params, 'post', 'json', {})
  },
  delRegCode(params) { // 删除检验码
    return fetch(`/api/manage/regcode/del/`+params, '', 'get', 'json', {})
  },
  resetSelfPassword(params) { // 修改密码
    return fetch(`/api/sys/admin/updateSelfPassword/`+params, '', 'get', 'json', {})
  }
}
