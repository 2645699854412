export default {
    setToken ({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    addRole({ commit }, role) {
      commit('ADD_ROLE',role);
    },
    updateRoles({ commit }, roles) {
      commit('UPDATE_ROLES',roles)
    }
};
