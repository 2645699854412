import login from '@/pages/login/login.vue'
import manage from './manage'

function load (component) {
    return () => import(`../pages/${component}.vue`)
}

const routes = [
    manage,
    {
        path: '/', redirect: '/manage'
    },
    {
        path: '/login',
        component: login
    }
]

export default routes