/**
 * Created by xiao on 2021/01/19.
 */
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { message } from 'ant-design-vue'

axios.defaults.timeout = 15000
axios.defaults.baseURL = ''

// type默认为form，如果要在请求体里传json，则传json，如果要上传文件，传file
function fetch (url = '', params = {}, method = 'get', contentType = 'form', headers = {}, responseType = 'json') {
  contentType === 'form' && (contentType = 'application/x-www-form-urlencoded')
  contentType === 'json' && (contentType = 'application/json')
  contentType === 'file' && (contentType = 'multipart/form-data')
  let query = Object.keys(params).map(k => {
    let val = params[k] === undefined ? '' : params[k]
    return `${k}=${val}`
  })
  let qs = query.join('&')
  if (method.toLowerCase() === 'get' && query.length > 0) {
    url += (url.indexOf('?') < 0 ? '?' : '&') + qs
  }
  if (contentType !== 'application/x-www-form-urlencoded' && method !== 'get') {
    qs = params
  }
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: qs,
      headers: {
        'Authorization': store.state.token,
        'Content-Type': contentType,
        ...headers
      }
      // responseType // TODO 会引起"Cannot read property 'code' of null" 先不放出来
    }).then(function (response) {
      if (response.status >= 200 && response.status < 400) {
        // console.log(response.Authorization)
        // console.log(response.headers['authorization'])
        if (response.data.code === 1000) { // 未登录
          router.push('/login')
          reject(new Error('invalid token'))
          return
        }
        //store.commit('SET_TOKEN', response.headers['authorization'])
        if (response.data.code === 1002) { // 无权限
          window.alert('你没有权限进行此操作，如需开权限，请联系管理员')
          reject(new Error('无权限'))
          return
        }
        resolve(response.data)
      } else {
        message.error(response.statusText)
      }
    }).catch(function (err) {
      if (err.toString().includes('timeout')) {
        message.error('请求失败：可能是当前网络较慢，或者服务器响应慢，请稍后重试')
      } else {
        message.error(url + '请求失败：' + err.message)
      }
      reject(err)
    })
  })
}

export default fetch
