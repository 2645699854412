export default {
    setLoading (state, bool) {
        state.loading = bool
    },
    SET_TOKEN (state, token) {
        state.token = token
        sessionStorage.setItem(`token`, token)
    },
    ADD_ROLE(state, role) {
     state.roles = state.roles.push(role);
    },
    UPDATE_ROLES(state,roles) {
     state.roles = [];
     state.roles = roles ;
     sessionStorage.setItem(`roles`, JSON.stringify(roles))
    },
    SET_USERINFO(state, userInfo) {
        state.userInfo = userInfo
    }
 
 }
 