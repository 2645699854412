<template>
  <div style="height: 100%;">
    <div style="width:100%;height: 90%;">
      <div style="width: 15%;height: 100%;float:left;">
        <a-button type="primary" style="margin-bottom: 16px" @click="toggleCollapsed">
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
        </a-button>
        <a-menu
          :default-selected-keys="['1']"
          mode="inline"
          theme="dark"
          :inline-collapsed="collapsed"
        >
          <a-menu-item key="1" @click="goTo('/manage/list')">
            <a-icon type="pie-chart" />
            <span>校验码管理</span>
          </a-menu-item>
          <a-menu-item key="2" v-if="isAdmin('system')" @click="goTo('/manage/user')">
            <a-icon type="desktop" />
            <span>用户管理</span>
          </a-menu-item>
          <a-menu-item key="3" @click="goTo('/manage/info')">
            <a-icon type="inbox" />
            <span>个人信息</span>
          </a-menu-item>
        </a-menu>
      </div>
      <router-view class="view" style="float:left;width: 85%"></router-view>
    </div>
    <!-- <div style="text-align: center;"><a href="http://beian.miit.gov.cn/" target="_blank">滇ICP备19007057号-1</a></div> -->
  </div>
</template>
<script>

export default {
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isAdmin(value) {
      // console.log(this.$store.state.roles)
      return this.hasPermission(this.$store.state.roles, value);
    },
    goTo(path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.replace(path)
      // this.$router.replace(path).catch(err => {
      //   console.log('输出报错',err)
      // })
    }
  },
};
</script>