
import manage from '@/pages/manage/manage.vue'

function load (component) {
  return () => import(`../pages/${component}.vue`)
}
export default {
  path: '/manage',
  component: manage,
  meta: { title: '校验码管理' },
  children: [
    { path: '', component: load('manage/list') },
    { path: 'list', component: load('manage/list') },
    { path: 'info', component: load('manage/info') }
  ]
}
