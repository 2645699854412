<template>
  <div style="height: 100%;">
    <router-view class="view"></router-view>
    <div style="text-align: center;"><a href="http://beian.miit.gov.cn/" target="_blank">滇ICP备19007057号-1</a></div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      
    };
  }
};
</script>