import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import router  from './router'
import api from './api'
import store from './store'
import {message,Modal} from 'ant-design-vue'

Vue.config.productionTip = false

Vue.prototype.$message = message
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$api = api
Vue.prototype.$indicator = {
  open () {
    store.commit('setLoading', true)
  },
  close () {
    store.commit('setLoading', false)
  }
}
Vue.prototype.hasPermission = function(roles,value){
  if(roles == null) {
    return false
  }
  for(let i = 0; i < roles.length;i++){
//      console.log("info"+roles[i].roleName);
     if(value==roles[i]){
        return true;
      }
  }
  return false;
}
Vue.use(Antd)
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
