<template>
<div>
  <div class="login-container">
    <h2 class="login-title">登录</h2>
    <a-form ref="form" :model="form" class="login-form">
      <h2 class="title">用户登录 LOGIN</h2>
      <a-form-item>
        <a-input v-model="form.username">
          <a-icon slot="prefix" type="user" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password v-model="form.password">
          <a-icon slot="prefix" type="lock" />
        </a-input-password>
      </a-form-item>

      <a-form-item>
        <a-button class="submit" type="primary" @click="onSubmit">登录</a-button>
      </a-form-item>
    </a-form>
  </div>
  <!-- <div style="text-align: center;"><a href="http://beian.miit.gov.cn/" target="_blank">滇ICP备19007057号-1</a></div> -->
</div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    }
  },
  methods: {
    onSubmit() {
      // console.log(this.form.username)
      // console.log('登陆成功！')
      this.$api.login.fetchLogin({
        userName: this.form.username,
        password: this.form.password
      }).then(res => {
        // console.log(res)
        // console.log(res.data.token)
        if (res.code === 200) {
          this.$store.commit('SET_TOKEN', res.data.token)
          this.$store.commit('UPDATE_ROLES', res.data.roles)
          this.goTo('/')
        } else {
          this.$message.error(res.message)
        }
        
      })
    },
    goTo(path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.replace(path)
      // this.$router.replace(path).catch(err => {
      //   console.log('输出报错',err)
      // })
    }
  },
}
</script>
<style>
.login-form {
  width: 565px;
  height: 372px;
  margin: 0 auto;
  /* background: url("../assets/houTaiKuang.png"); */
  padding: 40px 110px;
}

/* 背景 */
.login-container {
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  /* background: url("../assets/houTaiBg.png"); */
}

/* Log */
.login-title {
  color: #000;
  text-align: center;
  /* margin: 150px 0; */
  font-size: 48px;
  font-family: Microsoft Yahei;
}
/* 登陆按钮 */
.submit{
  width: 100%;
  height: 45px;
  font-size: 16px;
}
/* 用户登陆标题 */
.title{
  /* margin-bottom: 50px; */
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  font-family: Microsoft Yahei;
}
/* 输入框 */
/* .inputBox{
  height: 45px;
} */
/* 输入框内左边距50px */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
}
</style>

