/**
 * Created by ebi on 2017/5/11.
 */
import fetch from './fetch'
import login from './login'
import manage from './manage'

export default {
  login,
  manage,
  common: {
    uploadPath: '/commonApi/uploadFile',
    uploadPathNew: '/cloudapi_nc/orderservice/api/minFile/upload/v1?xservicename=oa-orderservice&collection=oa',
    downloadPath: '/api/staffAchievement/certificationApply/downloadFile',
    // pc的登陆验证token和session
    getUserInfo () {
      // return fetch('/commonApi/getLoginInfo')
      if (process.env.NODE_ENV === 'development') {
        return fetch('/commonApi/getLoginInfo')
      }
      return fetch('/commonApi/getPCLoginInfo')
    }
  }
}
