import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex);

const state = {
    token: sessionStorage.getItem("token"),
    userInfo: null,
    roles:JSON.parse(sessionStorage.getItem("roles")),
    loading: false
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
});